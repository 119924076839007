import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import logoShiloh from "../images/logoShiloh.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cement1 from "../images/building/cement1.png";
import cement2 from "../images/building/cement2.png";
import cement3 from "../images/building/cement3.png";
import pvcf from "../images/plumbing/pvcFittings.png";
import brickforce from "../images/hardware/brickforce.png";
import CommonBricks from "../images/building/CommonBricks.png";
import mwindow from "../images/wFrames/metal-window-frames.png";

function IndexPage() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear"
  };
  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`Shiloh`, `hardware`, `Shilohhardware`, `Zimbabwe`]}
      />
      <section className="text-center">
        <img
          src={logoShiloh}
          className="block mx-auto w-1/2"
          alt="Cat and human sitting on a couch"
        />

<h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>

        <p className="leading-loose">
          
        </p>
        <div>
        <h1 className = "font-bold text-2xl">Popular Products</h1>
        <Slider {...settings}>
          <div>
          <img src={cement1}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={cement2}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={cement3}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={pvcf}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={brickforce}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={CommonBricks}  alt="Sunset in the mountains"/>
          </div>
          <div>
          <img src={mwindow}  alt="Sunset in the mountains"/>
          </div>
        </Slider>
      </div>
    
      </section>

    </Layout>
  );
}

export default IndexPage;
